import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getApi, postApi } from 'src/common/apis';
import { TasksApi } from 'src/common/apis/task.api';
import { LoadingButton } from '@mui/lab';
import {
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Scrollbar from 'src/components/Scrollbar';

interface CompletedUser {
  _id: string;
  username: string;
}

interface PendingRequest {
  userId: {
    _id: string;
    username: string;
    socials: {
      instagram: string;
      twitter: string;
    };
  };
  status: string;
  _id: string;
  requestedAt: string;
}

interface TaskDetails {
  _id: string;
  title: string;
  description: string;
  points: number;
  imageUrl: string;
  redirectUrl: string;
  apiUrl?: string;
  isUnique: boolean;
  startDate: string;
  endDate: string;
  completedBy: CompletedUser[];
  pendingRequests: PendingRequest[];
  createdAt: string;
  updatedAt: string;
  completedUsersCount: number;
  pendingRequestsCount: number;
}

export default function TaskDetails() {
  const { id } = useParams();
  console.log('🚀 ~ TaskDetails ~ taskId:', id);
  const [taskDetails, setTaskDetails] = useState<TaskDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [approvingStates, setApprovingStates] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const response = await getApi({
          url: `${TasksApi.base}/${id}/details`,
        });
        console.log('🚀 ~ fetchTaskDetails ~ response:', response.data);
        if (response.data) {
          setTaskDetails(response?.data);
        }
      } catch (error) {
        console.error('Error fetching task details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTaskDetails();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!taskDetails) {
    return <div>Task not found</div>;
  }

  return (
    <Page title="Task Details">
      <Container>
        <HeaderBreadcrumbs
          heading="Task Details"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Daily Tasks', href: PATH_DASHBOARD.dailytask.root },
            { name: 'Task Details' },
          ]}
        />

        <Card sx={{ mb: 3 }}>
          <Box p={3}>
            <Typography variant="h6" gutterBottom>
              {taskDetails.title}
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              {taskDetails.description}
            </Typography>
            <Typography variant="body2">
              Points: {taskDetails.points}
            </Typography>
            <Typography variant="body2">
              URL :{' '}
              <a
                href={taskDetails.redirectUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                {taskDetails.redirectUrl}
              </a>
            </Typography>
          </Box>
        </Card>

        <Card sx={{ mb: 3 }}>
          <Box p={3}>
            <Typography variant="h6" gutterBottom>
              Completed Users ({taskDetails.completedUsersCount})
            </Typography>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Username</TableCell>
                      <TableCell>User ID</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskDetails.completedBy.map((user) => (
                      <TableRow key={user._id}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user._id}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>

        <Card>
          <Box p={3}>
            <Typography variant="h6" gutterBottom>
              Pending Requests ({taskDetails.pendingRequestsCount})
            </Typography>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Dashboard Username</TableCell>
                      <TableCell>Twitter</TableCell>
                      <TableCell>Instagram</TableCell>
                      <TableCell>User ID</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Requested At</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskDetails.pendingRequests.map((request) => {
                      const handleApprove = async () => {
                        try {
                          setApprovingStates((prev) => ({
                            ...prev,
                            [request._id]: true,
                          }));
                          await postApi({
                            url: `${TasksApi.base}/${id}/admin/complete`,
                            values: { username: request.userId.username },
                          });
                          // Refresh task details
                          const response = await getApi({
                            url: `${TasksApi.base}/${id}/details`,
                          });
                          if (response.data) {
                            setTaskDetails(response.data);
                          }
                        } catch (error) {
                          console.error('Error approving task:', error);
                        } finally {
                          setApprovingStates((prev) => ({
                            ...prev,
                            [request._id]: false,
                          }));
                        }
                      };

                      return (
                        <TableRow key={request._id}>
                          <TableCell>{request.userId.username}</TableCell>
                          <TableCell>
                            {request.userId?.socials?.twitter}
                          </TableCell>{' '}
                          <TableCell>
                            {request.userId?.socials?.instagram}
                          </TableCell>
                          <TableCell>{request.userId._id}</TableCell>
                          <TableCell
                            sx={{
                              color:
                                request.status === 'approved'
                                  ? 'success.main'
                                  : 'warning.main',
                            }}
                          >
                            {request.status}
                          </TableCell>
                          <TableCell>
                            {new Date(request.requestedAt).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="center">
                            {request.status === 'pending' && (
                              <LoadingButton
                                size="small"
                                variant="contained"
                                loading={approvingStates[request._id]}
                                onClick={handleApprove}
                              >
                                Approve
                              </LoadingButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
