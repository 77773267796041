// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  verifyMfa: path(ROOTS_AUTH, '/verify-mfa'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),

  //register

  verifyGst: path(ROOTS_AUTH, '/verify-gst'),
  uploadDocument: path(ROOTS_AUTH, '/upload-document'),
  authorizationDetails: path(ROOTS_AUTH, '/authorization-details'),
  thankyou: path(ROOTS_AUTH, '/thank_you'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  //Dashboard Paths
  analytics: path(ROOTS_DASHBOARD, '/analytics'),

  transactions: {
    root: path(ROOTS_DASHBOARD, '/transactions'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/transactions/${id}/edit`),
  },

  leaderboard: {
    root: path(ROOTS_DASHBOARD, '/leaderboard'),
  },

  entrypass: {
    root: path(ROOTS_DASHBOARD, '/entrypass'),
  },

  chains: {
    root: path(ROOTS_DASHBOARD, '/chains'),
  },

  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    details: (id: string) => path(ROOTS_DASHBOARD, `/users/${id}`),
  },

  questions: {
    root: path(ROOTS_DASHBOARD, '/questions'),
    createQuestion: path(ROOTS_DASHBOARD, '/questions/new'),
    editQuestion: (id: string) =>
      path(ROOTS_DASHBOARD, `/questions/${id}/edit`),
  },
  dailytask: {
    root: path(ROOTS_DASHBOARD, '/dailytask'),
    createTask: path(ROOTS_DASHBOARD, '/dailytask/new'),
    editTask: (id: string) =>
      path(ROOTS_DASHBOARD, `/dailytask/${id}/edit`),
  },

  product: {
    cart: path(ROOTS_DASHBOARD, '/carts'),
    products: path(ROOTS_DASHBOARD, '/products'),
    createProduct: path(ROOTS_DASHBOARD, '/products/new'),
    editProduct: (id: string) => path(ROOTS_DASHBOARD, `/products/${id}/edit`),
  },

  rewards: {
    root: path(ROOTS_DASHBOARD, '/rewards'),
  },

  points: {
    root: path(ROOTS_DASHBOARD, '/points'),
  },

  staff: {
    root: path(ROOTS_DASHBOARD, '/staff'),
    createStaff: path(ROOTS_DASHBOARD, '/staff/new'),
    editStaff: (id: string) => path(ROOTS_DASHBOARD, `/staff/${id}/edit`),
  },

  helpsupport: path(ROOTS_DASHBOARD, '/help-and-support'),
  helpsupportChat: (chatId: string) =>
    path(ROOTS_DASHBOARD, `/help-and-support/chat/${chatId}`),

  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_DOCS = '';
